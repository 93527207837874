<template>
  <div>
    <app-navbar />
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="4"
          >
            <br><br><br>
            <base-material-card
              class="v-card-profile"
              avatar="/Login.jpg"
            >
              <v-card-text class="text-center">
                <h4 class="text-h3 font-weight-light mb-3 black--text">
                  Iniciar sesión
                </h4>
              </v-card-text>
              <v-form
                v-model="isValid"
                @submit="(e) => handleSubmit(e)"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        required
                        class="purple-input"
                        label="Email"
                        type="Email"
                        name="email"
                        :disabled="fetchingLogin"
                        outlined
                        @keyup.enter="handleSubmit"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="password"
                        :rules="passwordRules"
                        required
                        :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="show ? 'text' : 'password'"
                        :disabled="fetchingLogin"
                        name="input-10-1"
                        label="Contraseña"
                        outlined
                        @click:append="show = !show"
                        @keyup.enter="handleSubmit"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-5"
                    >
                      <v-btn
                        color="secondary"
                        class="mr-0"
                        block
                        :loading="fetchingLogin"
                        type="submit"
                      >
                        Ingresar &nbsp; <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <router-link
                        to="/recover"
                        style="text-decoration: none; color: #0094C9;"
                      >
                        ¿No recuerda su contraseña?
                      </router-link>
                    </v-col>
                    <v-col class="mb-1" />
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>
        </v-row>

        <v-dialog
          v-model="dialog"
          max-width="500"
        >
          <v-card class="text-center">
            <v-card-title>
              Cambio de contraseña

              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="dialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <br><br>

            <v-card-text>
              <v-form
                v-model="isValidPass"
                @submit="e => updatePass(e)"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="passForm.password"
                        :rules="newPasswordRules"
                        required
                        outlined
                        :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="show2 ? 'text' : 'password'"
                        label="Nueva contraseña"
                        @click:append="show2 = !show2"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="repeatPass"
                        :rules="[v => !!v || 'La contraseña es requerido', passwordRepeatRules]"
                        outlined
                        :append-icon="show3 ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="show3 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Repita su contraseña"
                        @click:append="show3 = !show3"
                      />
                    </v-col>
                    <v-col
                      v-if="!equalPass"
                      class="p-0"
                    >
                      <p
                        class="red--text"
                        :style="{
                          fontSize: '12pt'
                        }"
                      >
                        Las contraseñas no coinciden
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-5"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        block
                        :disabled="!equalPass"
                        type="submit"
                        :loading="fetchingResetPassword"
                      >
                        Enviar &nbsp; <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="mb-1" />
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
      <br><br><br>
      <app-footer simple />
    </v-main>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import AppNavbar from '@/components/Navbar.vue'
  import AppFooter from '@/components/Footer.vue'
  import axios from 'axios'
  export default {
    name: 'Login',
    components: {
      AppNavbar,
      AppFooter,
    },
    data () {
      return {
        show: false,
        show2: false,
        show3: false,
        isValid: false,
        isValidPass: false,
        dialog: false,
        email: '',
        password: '',
        repeatPass: '',
        // equalPass: true,
        passForm: {
          oldPassword: '',
          password: '',
        },
        emailRules: [
          v => !!v || 'El email es requerido',
          v => /.+@.+/.test(v) || 'Debe ingresar un email valido',
        ],
        passwordRules: [
          v => !!v || 'La contraseña es requerida',
        ],
      }
    },
    computed: {
      ...mapState([
        'loginData',
        'fetchingLogin',
        'fetchingResetPassword',
      ]),

      equalPass () {
        return this.passForm.password === this.repeatPass
      },

      newPasswordRules () {
        return [
          v => !!v || 'La contraseña es requerida',
        ]
      },
      passwordRepeatRules () {
        return () => this.repeatPass === this.passForm.password || 'Las contraseñas no coinciden'
      },
    },
    watch: {
      async loginData (loginData) {
        if (loginData.change_password) {
          this.dialog = true
          this.passForm.oldPassword = this.password
          this.passForm.token = loginData.token
          // // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.passForm)
        } else {
          localStorage.setItem('token', loginData.token)
          axios.defaults.headers.common = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
          this.setUser({
            ...loginData,
            password: null,
            token: null,
          })
          // All processes are completed
          this.$router.push('/dashboard')
        }
      },
    },
    methods: {
      ...mapActions([
        'login',
        'setUser',
        'setPassword',
      ]),
      async handleSubmit (e) {
        e.preventDefault()

        if (this.isValid) {
          await this.login({
            email: this.email,
            password: this.password,
          })
        }
      },
      async updatePass (e) {
        e.preventDefault()

        if (this.isValidPass) {
          await this.setPassword(this.passForm)
          this.dialog = false
          this.password = ''
        }
      },
    },
  }
</script>

<style scoped>
  router-link a{
      text-decoration: none;
  }
</style>
